import { createSlice } from "@reduxjs/toolkit";
import StateSelector from "../selector";
import { ATSSStorage, ATSSStorageKeys } from '../../utils/atss_storage';
import moment from "moment";

const initialState = {
    permits: [],
    selectedPermit: null,
    loading: false,
    failed: false,
    silentRefresh: false,
    lastRefresh: moment().toISOString(),
    permitTypes: [],
    permitPagination : { page: 1, pageSize: 10 },
    vehicle : [],
    exportLoading : false,
    importLoading : false,
    vehicles : [],
    selectedPlant: null,
    plants : [],
    requiredPermits: [],
    requiredPermitPagination : { page: 1, pageSize: 10 },
    requiredPermitLoading: false,
    projectSites : [],
};

const storage = new ATSSStorage();

export const permitSlice = createSlice({
    name: 'permit',
    initialState,
    reducers: {
        setPermits: (state, action) => {
            state.permits = action.payload;
        },
        setRequiredPermits: (state, action) => {
            state.requiredPermits = action.payload;
        },
        setPermitTypes: (state, action) => {
            state.permitTypes = action.payload;
        },
        setSelectedPermit: (state, action) => {
            state.selectedPermit = action.payload;
        },
        setVehicle: (state, action) => {
            state.vehicle = action.payload;
        },
        setVehicles: (state, action) => {
            state.vehicles = action.payload;
        },
        setSelectedPlant: (state, action) => {
            state.selectedPlant = action.payload;
        },
        setPlants: (state, action) => {
            const plants = action.payload.filter(f => f.ZoneTypeEx === 1);
            state.plants = plants;
            if (storage.getSessionItem(ATSSStorageKeys.permitBatchingPlant) !== null && storage.getSessionItem(ATSSStorageKeys.permitBatchingPlant) !== undefined) {
                state.selectedPlant = storage.getSessionItem(ATSSStorageKeys.permitBatchingPlant);
            } else if (plants.length > 0) {
                state.selectedPlant = plants[0];
            }
        },
        setPermitPagination: (state, action) => {
            state.permitPagination = action.payload;
        },
        setRequiredPermitPagination: (state, action) => {
            state.requiredPermitPagination = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setRequiredPermitLoading: (state, action) => {
            state.requiredPermitLoading= action.payload;
        },
        setExportLoading: (state, action) => {
            state.exportLoading = action.payload;
        },
        setImportLoading: (state, action) => {
            state.importLoading = action.payload;
        },
        setProjectSites: (state, action) => {
            state.projectSites = action.payload;
        },
        setFailed: (state, action) => {
            state.failed = action.payload;
        },
        setSilentRefresh: (state, action) => {
            state.silentRefresh = action.payload;
            state.lastRefresh = moment().toISOString();
        },
        resetPermitState: (state) => {
            return initialState;
        }
    }
});

export const {
    setPermits,
    setSelectedPermit,
    setLoading,
    setFailed,
    setSilentRefresh,
    resetPermitState,
    setPermitTypes,
    setVehicle,
    setPermitPagination,
    setExportLoading,
    setImportLoading,
    setSelectedPlant,
    setVehicles,
    setPlants,
    setRequiredPermits,
    setRequiredPermitPagination,
    setRequiredPermitLoading,
    setProjectSites,
} = permitSlice.actions;

export const permitSelector = StateSelector('permit');

export default permitSlice.reducer;